.quiz {
  background-position: center;
  background-size: cover;

  &__content {
    padding-top: 46px;
    padding-bottom: 44px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding-top: 29px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 46px;

    background-color: rgba(255, 255, 255, 0.8);
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__text {
    align-self: center;
    max-width: 170px;
    margin-bottom: 21px;

    font-size: 14px;
    line-height: 1.14;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .quiz {
    &__content {
      padding-top: 81px;
      padding-bottom: 88px;
    }

    &__block {
      padding-top: 47px;
      padding-bottom: 51px;
    }

    &__title {
      margin-bottom: 84px;

      font-size: 45px;
    }

    &__text {
      max-width: none;
      font-size: 20px;
    }
  }
}

@media (min-width: 1200px) {
  .quiz {
  }
}
