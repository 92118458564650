.rooms {
  $p: &;

  position: relative;

  background-color: map-get($colors, 'bg');

  #rooms {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 59px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__item {
    position: relative;

    border: 1px solid #e2e2e2;
    background-color: rgba(255, 255, 255, 0.72);

    &:nth-child(n + 7) {
      display: none;
    }
  }

  &__stock {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 9px;

    display: flex;
    align-items: center;
    height: 23px;
    padding-left: 8px;
    padding-right: 8px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    border-radius: 3px;
    color: #fff;
    background-color: map-get($colors, 'main');
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 68%;
    // aspect-ratio: 1 / 0.68;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 10px;

    display: flex;
    column-gap: 5px;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 8px;
      height: 1px;

      background-color: rgba(255, 255, 255, 0.57);

      &-active {
        background-color: map-get($colors, 'main');
      }
    }
  }

  &__middle {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 13px;

    border-bottom: 1px solid #c8c8c8;
  }

  &__name {
    margin-bottom: 7px;

    font-size: 18px;
    line-height: 1.17;
  }

  &__prices {
    display: flex;
    align-items: baseline;

    del {
      margin-right: 8px;

      font-size: 12px;
      line-height: 1.17;
      text-decoration: line-through;

      color: #8c8c8c;
    }

    strong {
      margin-right: 1px;

      font-size: 15px;
      font-weight: 700;
      line-height: 1.2;

      color: map-get($colors, 'main');
    }

    span {
      font-size: 12px;
      line-height: 1.17;

      color: #717171;
    }
  }

  &__body {
    padding-top: 13px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 18px;
  }

  &__square {
    display: flex;
    margin-bottom: 7px;

    span {
      margin-right: 4px;

      font-size: 13px;
      font-weight: 300;
      line-height: 1.15;

      color: rgba(0, 0, 0, 0.55);
    }

    strong {
      font-size: 13px;
      line-height: 1.15;
    }
  }

  &__seats {
    display: flex;
    margin-bottom: 7px;

    span {
      margin-right: 4px;

      font-size: 13px;
      font-weight: 300;
      line-height: 1.15;

      color: rgba(0, 0, 0, 0.55);
    }

    strong {
      font-size: 13px;
      line-height: 1.15;
    }
  }

  &__additional {
    display: flex;
    margin-bottom: 7px;

    span {
      margin-right: 4px;

      font-size: 13px;
      font-weight: 300;
      line-height: 1.15;

      color: rgba(0, 0, 0, 0.55);
    }

    strong {
      font-size: 13px;
      line-height: 1.15;
    }
  }

  &__food {
    display: flex;
    margin-bottom: 7px;

    span {
      margin-right: 4px;

      font-size: 13px;
      font-weight: 300;
      line-height: 1.15;

      color: rgba(0, 0, 0, 0.55);
    }

    strong {
      font-size: 13px;
      line-height: 1.15;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 50px;
    margin-bottom: 15px;

    font-size: 12px;
    font-weight: 300;
    line-height: 1.4;

    color: rgba(0, 0, 0, 0.85);
  }

  &__calculation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'second');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second-dark');
    }
  }

  &__more {
    display: flex;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    text-transform: uppercase;

    color: map-get($colors, 'main');

    transition: color 0.2s;

    &:hover {
      color: map-get($colors, 'second');
    }
  }

  &_opened {
    #{$p}__list {
      margin-bottom: 0;
    }

    #{$p}__item {
      &:nth-child(n + 7) {
        display: block;
      }
    }

    & #{$p}__more {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .rooms {
    #rooms {
      top: -110px;
    }

    &__content {
      padding-top: 76px;
    }

    &__title {
      margin-bottom: 63px;

      font-size: 45px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 30px;
    }

    &__item {
      width: calc(50% - 15px);
    }

    &__stock {
      top: 12px;
      left: 10px;
    }

    &__slide {
      padding-bottom: 64%;
      // aspect-ratio: 1 / 0.64;
    }

    &__name {
      font-size: 21px;
    }

    &__prices {
      del {
        margin-right: 11px;

        font-size: 17px;
      }

      strong {
        margin-right: 5px;

        font-size: 21px;
      }
    }

    &__body {
      padding-top: 17px;
    }

    &__block {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
    }

    &__square {
      width: 60%;
      margin-bottom: 12px;
    }

    &__seats {
      order: 1;
      width: 60%;
      margin-bottom: 0;
    }

    &__additional {
      width: 40%;
      margin-bottom: 12px;
    }

    &__food {
      order: 1;
      width: 40%;
      margin-bottom: 0;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__more {
      margin-top: 32px;
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    &__content {
      padding-top: 111px;
    }

    &__item {
      width: calc(33.33333% - 20px);
    }

    &__slide {
      padding-bottom: 60%;
      // aspect-ratio: 1 / 0.6;
    }

    &__middle {
      padding-left: 17px;
      padding-right: 17px;
    }

    &__body {
      padding-left: 17px;
      padding-right: 17px;
    }

    &__more {
      margin-top: 36px;
    }
  }
}
