// .steps {
//   margin-bottom: 10px;

//   font-size: 12px;
//   line-height: 1.25;
//   text-transform: uppercase;
//   text-align: center;

//   color: #5c5c5c;
// }

.slider {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .module {
    max-width: 277px;
    margin-left: auto;
    margin-right: auto;
  }
}
