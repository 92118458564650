.about {
  $p: &;

  position: relative;

  background-color: map-get($colors, 'bg');

  #about {
    position: absolute;
    top: -68px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 49px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 29px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__text {
    margin-bottom: 33px;
  }

  &__one {
    margin-bottom: 22px;

    p {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.42;
      text-align: center;

      color: rgba(0, 0, 0, 0.8);

      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }

  &__two {
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;

    font-size: 17px;
    font-weight: 300;
    line-height: 1.42;
    text-align: center;

    color: rgba(255, 255, 255, 0.87);
    background-color: map-get($colors, 'main');
  }

  &__bottom {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.42;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .about {
    #about {
      top: -110px;
    }

    &__content {
      padding-top: 63px;
    }

    &__title {
      align-items: flex-start;

      font-size: 45px;
    }

    &__one {
      p {
        text-align: left;
      }
    }

    &__two {
      padding-left: 33px;
      padding-right: 67px;

      text-align: left;
    }

    &__bottom {
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    &__content {
      padding-top: 97px;
    }

    &__title {
      margin-bottom: 81px;
    }

    &__text {
      display: flex;
      margin-bottom: 66px;
    }

    &__one {
      width: calc(50% - 15px);
      margin-right: 15px;
      margin-bottom: 0;

      p {
        &:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }

    &__two {
      width: calc(50% - 15px);
      margin-left: 15px;
      padding-top: 47px;
      padding-left: 52px;
      padding-right: 46px;
      padding-bottom: 47px;
    }

    &__bottom {
      font-size: 25px;
    }
  }
}
