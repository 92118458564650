.reviews {
  $p: &;

  position: relative;

  #reviews {
    position: absolute;
    top: -68px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 47px;
    padding-bottom: 72px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__slider {
    position: relative;

    margin-bottom: 25px;
  }

  &__slide {
    border: 1px solid #e2e2e2;
    background-color: rgba(244, 243, 241, 0.53);

    &_clipped {
      #{$p}__text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      #{$p}__button {
        visibility: visible;
      }
    }
  }

  &__top {
    margin-bottom: 12px;

    padding-top: 17px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 16px;

    border-bottom: 1px solid #c8c8c8;
  }

  &__name {
    font-size: 21px;
    line-height: 1.19;
  }

  &__grade {
    font-size: 14px;
    line-height: 1.4;

    strong {
      font-weight: 700;

      color: map-get($colors, 'main');
    }

    span {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__body {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 17px;
  }

  &__text {
    min-height: 105px;
    margin-right: 30px;
    margin-bottom: 16px;

    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.17;
    text-transform: uppercase;

    color: map-get($colors, 'main');
    visibility: hidden;

    transition: color 0.2s;

    &:hover {
      color: map-get($colors, 'second');
    }
  }

  &__date {
    font-size: 12px;
    line-height: 1.17;

    color: #bebebe;
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__add {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 47px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    border: 1px solid map-get($colors, 'main');
    color: map-get($colors, 'main');

    transition: color 0.2s, background 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    overflow: hidden;

    #reviews {
      top: -110px;
    }

    &__content {
      position: relative;

      padding-top: 83px;
      padding-bottom: 76px;
    }

    &__title {
      align-items: flex-start;
      margin-bottom: 44px;

      font-size: 45px;
    }

    &__slider {
      margin-bottom: 0;

      .swiper {
        overflow: visible;
      }

      .swiper-slide {
        &:not(.swiper-slide-active):not(.swiper-slide-active + .swiper-slide) {
          opacity: 0.3;

          pointer-events: none;
        }
      }
    }

    &__top {
      margin-bottom: 19px;
      padding-top: 14px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 19px;
    }

    &__grade {
      font-size: 15px;
    }

    &__body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 25px;
    }

    &__text {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &__date {
      font-size: 14px;
    }

    &__add {
      position: absolute;
      top: 89px;
      right: 0;
    }

    &__prev {
      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__next {
      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    &__content {
      padding-top: 101px;
      padding-bottom: 115px;
    }

    &__title {
      margin-bottom: 82px;
    }

    &__slider {
      margin-left: 180px;
      margin-right: 180px;
    }

    &__top {
      margin-bottom: 24px;
      padding-top: 31px;
      padding-left: 31px;
      padding-right: 31px;
      padding-bottom: 27px;
    }

    &__name {
      margin-bottom: 5px;
    }

    &__body {
      padding-left: 31px;
      padding-right: 31px;
      padding-bottom: 31px;
    }

    &__text {
      margin-bottom: 24px;
    }

    &__prev {
      left: -209px;

      width: 58px;

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        left: 26px;

        width: 12px;
      }
    }

    &__next {
      right: -209px;

      width: 58px;

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        right: 26px;

        width: 12px;
      }
    }

    &__add {
      top: 108px;
    }
  }
}
