@import '/src/styles/utils/functions';

$radio-color: #1191a5;

.module {
  width: 26px;
  aspect-ratio: 1;

  border: 1px solid #c9c9c9;
  border-radius: 50%;
  cursor: pointer;

  appearance: none;

  &:checked {
    background-image: svg-encode(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><circle cx="10" cy="10" r="6" fill="#{$radio-color}" /></svg>'
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
