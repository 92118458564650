.module {
  height: 52px;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;

  font-size: 14px;

  border: 1px solid #c6c6c6;

  &::placeholder {
    color: rgba(0, 0, 0, 0.41);
  }
}

@media (min-width: 768px) {
}
