.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrival {
  margin-bottom: 15px;

  background-color: rgba(255, 255, 255, 0.57);
}

.departure {
  background-color: rgba(255, 255, 255, 0.57);
}
