.contacts {
  position: relative;

  background-color: map-get($colors, 'bg');

  #contacts {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 40px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__block {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;

    background-color: #fff;
  }

  &__phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    span {
      margin-bottom: 2px;

      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;

      color: rgba(0, 0, 0, 0.54);
    }

    a {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.17;
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    span {
      margin-bottom: 2px;

      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;

      color: rgba(0, 0, 0, 0.54);
    }

    a {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.17;
    }
  }

  &__whatsapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    a {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.17;
    }

    div {
      max-width: 160px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.33;
      text-align: center;

      span {
        color: rgba(0, 0, 0, 0.52);
      }

      img {
        position: relative;
        top: 2px;

        width: 13px;
      }

      p {
        display: inline;

        strong {
          color: rgba(0, 0, 0, 0.78);
        }
      }
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    span {
      margin-bottom: 2px;

      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;

      color: rgba(0, 0, 0, 0.54);
    }

    strong {
      max-width: 270px;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
    }
  }

  &__email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    span {
      margin-bottom: 2px;

      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;

      color: rgba(0, 0, 0, 0.54);
    }

    a {
      max-width: 270px;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
    }
  }

  &__networks {
    div {
      display: flex;
      justify-content: center;
      column-gap: 14px;
      margin-bottom: 13px;

      a {
        display: flex;
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;
      text-align: center;

      color: rgba(0, 0, 0, 0.54);
    }
  }

  &__map {
    height: 421px;
  }
}

@media (min-width: 576px) {
  .contacts {
    &__block {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -110px;
    }

    &__content {
      position: relative;

      padding-top: 82px;
    }

    &__title {
      margin-bottom: 68px;

      font-size: 45px;
    }

    &__block {
      position: absolute;
      top: 269px;
      left: 0;

      margin-left: 0;
      margin-right: 0;
      padding-top: 27px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 29px;
    }

    &__phone {
      align-items: flex-start;
      margin-bottom: 14px;
    }

    &__second {
      align-items: flex-start;
      margin-bottom: 16px;
    }

    &__whatsapp {
      align-items: flex-start;

      div {
        max-width: none;
      }
    }

    &__address {
      align-items: flex-start;
      margin-bottom: 17px;

      strong {
        text-align: left;
      }
    }

    &__email {
      align-items: flex-start;
      margin-bottom: 17px;
    }

    &__networks {
      div {
        justify-content: flex-start;
        margin-bottom: 17px;
      }

      p {
        text-align: left;
      }
    }

    &__map {
      height: 546px;
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    &__content {
      padding-top: 99px;
    }

    &__title {
      margin-bottom: 65px;
    }

    &__block {
      top: 313px;

      padding-top: 52px;
      padding-left: 65px;
      padding-right: 62px;
      padding-bottom: 61px;
    }

    &__map {
      height: 746px;
    }

    &__phone {
      margin-bottom: 22px;
    }

    &__second {
      margin-bottom: 30px;
    }

    &__whatsapp {
      margin-bottom: 30px;

      a {
        margin-bottom: 6px;
      }
    }

    &__address {
      margin-bottom: 32px;
    }

    &__email {
      margin-bottom: 57px;
    }

    &__networks {
      div {
        margin-bottom: 15px;
      }
    }
  }
}
