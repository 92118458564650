.stocks {
  position: relative;

  #stocks {
    position: absolute;
    top: -68px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    padding-bottom: 33px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__note {
    align-self: center;
    margin-bottom: 34px;
    padding: 6px 18px;

    font-size: 12px;
    line-height: 1.4;
    text-align: center;

    border-radius: 4px;
    background-color: #fbfbfb;
  }

  &__slider {
    position: relative;
    // margin-left: 25px;
    // margin-right: 25px;
  }

  &__slide {
    display: flex;
    flex-direction: column;

    p {
      aspect-ratio: 1;
      margin-left: 25px;
      margin-right: 25px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 30px;

      background-color: rgba(244, 243, 241, 0.27);

      h4 {
        margin-bottom: 16px;
        padding-bottom: 16px;

        font-size: 21px;
        line-height: 1.19;
        text-align: center;

        border-bottom: 1px solid #c8c8c8;
      }

      span {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 84px;
        align-self: center;
        max-width: 210px;
        margin-bottom: 24px;

        font-size: 15px;
        font-weight: 300;
        line-height: 1.4;
        text-align: center;

        color: rgba(0, 0, 0, 0.85);
      }

      button {
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 143px;
        height: 47px;

        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        border: 1px solid map-get($colors, 'second');
        color: map-get($colors, 'second');
      }
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;
    margin-top: calc((100% - 50px) / 2);

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;
    margin-top: calc((100% - 50px) / 2);

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__list {
    display: none;
  }

  &__more {
    display: none;
  }
}

@media (min-width: 768px) {
  .stocks {
    $p: &;

    #stocks {
      top: -110px;
    }

    &__content {
      position: relative;

      padding-top: 43px;
      padding-bottom: 31px;
    }

    &__title {
      align-items: flex-start;
      margin-bottom: 59px;

      font-size: 45px;
    }

    &__note {
      position: absolute;
      top: 80px;
      right: 0;
    }

    &__slider {
      display: none;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 30px;
      margin-bottom: 21px;
    }

    &__item {
      position: relative;

      display: flex;
      width: calc(50% - 15px);

      &:nth-child(n + 5) {
        display: none;
      }

      div {
        width: 69%;
        padding-top: 22px;
        padding-left: 15px;
        padding-bottom: 24px;

        background-color: rgba(244, 243, 241, 0.27);
      }

      h4 {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;

        border-bottom: 1px solid #c8c8c8;
      }

      span {
        max-width: 185px;
        min-height: 38px;

        line-height: 1.19;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 185px;
        min-height: 50px;
        margin-bottom: 20px;

        font-size: 12px;
        font-weight: 300;
        line-height: 1.4;

        color: rgba(0, 0, 0, 0.85);
      }

      img {
        position: absolute;
        top: 20px;
        right: 0;

        width: 40%;
        height: calc(100% - 40px);
        object-fit: cover;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 39px;

        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        border: 1px solid map-get($colors, 'second');
        color: map-get($colors, 'second');

        transition: color 0.2s, background 0.2s;

        &:hover {
          color: #fff;
          background-color: map-get($colors, 'second');
        }
      }
    }

    &__more {
      align-self: center;
      display: flex;

      font-size: 14px;
      font-weight: 600;
      line-height: 1.14;
      text-transform: uppercase;

      color: map-get($colors, 'main');

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'second');
      }
    }

    &_opened {
      #{$p}__list {
        margin-bottom: 0;
      }

      #{$p}__item {
        &:nth-child(n + 5) {
          display: flex;
        }
      }

      & #{$p}__more {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .stocks {
    &__content {
      padding-top: 92px;
      padding-bottom: 85px;
    }

    &__title {
      margin-bottom: 83px;
    }

    &__note {
      top: 111px;
    }

    &__list {
      margin-bottom: 17px;
    }

    &__item {
      div {
        padding-top: 30px;
        padding-left: 32px;
        padding-bottom: 28px;
      }

      h4 {
        margin-bottom: 16px;
        padding-bottom: 22px;
      }

      span {
        max-width: 280px;
        min-height: 50px;

        font-size: 21px;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 280px;
        min-height: 84px;
        margin-bottom: 64px;

        font-size: 15px;
      }

      button {
        width: 143px;
        height: 47px;
      }

      img {
        top: 27px;
        height: calc(100% - 54px);

        width: 42%;
      }
    }
  }
}
