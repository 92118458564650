.module {
  position: relative;

  width: 743px;
  max-width: 100%;
  padding-top: 73px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 77px;
  overflow: hidden;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 277px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 29px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;
  text-align: center;
}

.name {
  margin-bottom: 15px;
}

.phone {
  margin-bottom: 14px;
}

.policy {
  margin-bottom: 14px;
}

.button {
  align-self: center;
  width: 277px;
  height: 50px;

  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #bf9570;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#bf9570, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
