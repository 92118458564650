@import '/src/styles/utils/functions';

$corner-color: rgba(0, 0, 0, 0.24);

.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 52px;

  font-size: 14px;

  border: 1px solid #c6c6c6;
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 10px;
}

.title {
  padding-left: 22px;

  color: rgba(0, 0, 0, 0.41);
}

.select {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 14px;

  cursor: pointer;
}
