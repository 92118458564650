.module {
  position: relative;

  width: 972px;
  max-width: 100%;
  padding-top: 74px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 51px;
  overflow: hidden;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.slider {
  position: relative;

  margin-bottom: 44px;
}

.slide {
  display: flex;
  aspect-ratio: 1 / 0.51;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.nav {
  :global {
    .swiper-button-lock {
      display: none;
    }
  }
}

.prev {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  aspect-ratio: 1;

  transform: translateY(-50%);

  &::before {
    width: 33px;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: rgba(5, 119, 168, 0.51);
    backdrop-filter: blur(4px);

    transition: transform 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 17px;
    left: 18px;

    width: 7px;
    aspect-ratio: 1;

    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: rotate(-45deg);

    content: '';
  }

  &:hover {
    &::before {
      transform: scale(1.1);
    }
  }

  &:active {
    &::before {
      transform: scale(0.9);
    }
  }
}

.next {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  aspect-ratio: 1;

  transform: translateY(-50%);

  &::before {
    width: 33px;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: rgba(5, 119, 168, 0.51);
    backdrop-filter: blur(4px);

    transition: transform 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 17px;
    right: 18px;

    width: 7px;
    aspect-ratio: 1;

    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);

    content: '';
  }

  &:hover {
    &::before {
      transform: scale(1.1);
    }
  }

  &:active {
    &::before {
      transform: scale(0.9);
    }
  }
}

.pagination {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 18px;

  display: flex;
  column-gap: 12px;

  transform: translateX(-50%);

  &:global(.swiper-pagination-lock) {
    display: none;
  }

  :global {
    .swiper-pagination-bullet {
      width: 11px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.32);

      &-active {
        background-color: #1191a5;
      }
    }
  }
}

.block {
}

.name {
  margin-bottom: 24px;
  padding-bottom: 23px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;

  border-bottom: 1px solid #c8c8c8;
}

.text {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.42;

  color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 768px) {
  .module {
    padding-left: 59px;
    padding-right: 59px;
  }

  .prev {
    &::before {
      width: 39px;
    }

    &::after {
      width: 8px;
    }
  }

  .next {
    &::before {
      width: 39px;
    }

    &::after {
      width: 8px;
    }
  }
}

@media (min-width: 1200px) {
  .prev {
    left: 30px;

    width: 58px;

    &::before {
      width: 58px;
    }

    &::after {
      top: 23px;
      left: 26px;

      width: 12px;
    }
  }

  .next {
    right: 30px;

    width: 58px;

    &::before {
      width: 58px;
    }

    &::after {
      top: 23px;
      right: 26px;

      width: 12px;
    }
  }
}
