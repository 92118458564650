.advantages {
  position: relative;

  #advantages {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 42px;
    padding-bottom: 48px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    column-gap: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 30px / 2);

    img {
      width: 28px;
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.14;
    }
  }
}

@media (min-width: 768px) {
  .advantages {
    #advantages {
      top: -110px;
    }

    &__content {
      padding-bottom: 66px;
    }

    &__list {
      row-gap: 45px;
    }

    &__item {
      width: calc(25% - 90px / 4);

      img {
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .advantages {
    &__content {
      padding-top: 77px;
      padding-bottom: 76px;
    }

    &__list {
      row-gap: 55px;
    }

    &__item {
      flex-direction: row;

      img {
        width: 38px;
        margin-right: 17px;
        margin-bottom: 0;
      }

      span {
        font-size: 21px;
      }
    }
  }
}
