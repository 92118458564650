.body {
  // display: grid;
  // grid-template-columns: 1fr repeat(12, 100px) 1fr;
  // grid-template-columns: 20px 1fr 20px;

  // .header {
  //   grid-column: 2 / -2;
  // }

  // .main {
  //   grid-column: 2 / -2;
  // }

  // .footer {
  //   grid-column: 2 / -2;
  // }

  &_blocked {
    overflow: hidden;
  }

  // .main {
  //   overflow: hidden;
  // }
}
