.module {
  position: relative;

  width: 743px;
  max-width: 100%;
  padding-top: 73px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 77px;
  overflow: hidden;

  background-color: #f4f3f1;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 277px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 11px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;
  text-align: center;
}

.grade {
  margin-bottom: 38px;
}

.gradeTitle {
  margin-bottom: 16px;

  font-size: 12px;
  font-weight: 300;
  line-height: 1.17;
  text-align: center;

  color: rgba(0, 0, 0, 0.54);
}

.gradeList {
  display: flex;
  justify-content: space-between;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-bottom: 7px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.17;

    color: rgba(0, 0, 0, 0.54);
  }
}

.name {
  margin-bottom: 15px;
}

.email {
  margin-bottom: 15px;
}

.text {
  margin-bottom: 15px;
}

.policy {
  margin-bottom: 14px;
}

.button {
  align-self: center;
  width: 277px;
  height: 50px;

  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #bf9570;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#bf9570, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
