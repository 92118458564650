.module {
  position: relative;

  width: 970px;
  max-width: 100%;
  padding-top: 73px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 77px;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  margin-bottom: 29px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;
  text-align: center;
}

.content {
  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
