@import '/src/styles/utils/functions';

$corner-color: #d4d4d4;

.module {
  display: flex;
  align-items: center;
  height: 52px;
  padding-left: 22px;
  padding-right: 35px;

  border: 1px solid #c6c6c6;
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 10px;
  cursor: pointer;

  span {
    margin-right: auto;

    font-size: 14px;

    color: rgba(0, 0, 0, 0.41);
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  padding-top: 27px;
  padding-left: 22px;

  padding-bottom: 18px;

  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 39px;
  max-height: 200px;
  padding-right: 18px;
  overflow-y: scroll;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

div.select {
  width: 127px;
  height: 30px;

  border: none;
  border-bottom: 1px solid #a6a6a6;
  color: #bf9570;
  background-position: right 0 center;

  select {
    padding-left: 0;
  }
}

.button {
  margin-right: 20px;
  height: 46px;

  font-size: 15px;

  color: #fff;
  background-color: #bf9570;

  text-align: center;
  text-transform: uppercase;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#bf9570, 10%);
  }
}
