.screen {
  padding-top: 68px;
  overflow: hidden;

  &__slider {
    position: relative;
  }

  &__slide {
    height: 404px;

    background-position: center;
    background-size: cover;
  }

  &__block {
    position: absolute;
    z-index: 1;
    top: 68px;
    left: 0;

    width: 100%;
    height: calc(100% - 68px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 69px;

    animation-name: slideUp;
    animation-duration: 1s;
  }

  &__stars {
    margin-bottom: 19px;
  }

  &__title {
    margin-bottom: 19px;
  }

  &__text {
    width: 280px;
    margin-bottom: 9px;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 19px;

    font-size: 13px;
    line-height: 1.8;
    text-transform: uppercase;
    text-align: center;

    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
  }

  &__offer {
    margin-bottom: 34px;

    font-size: 12px;
    line-height: 1.42;

    color: rgba(255, 255, 255, 0.67);
  }

  &__calculation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 47px;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'second');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second-dark');
    }
  }

  // &__nav {
  //   display: none;
  // }

  &__pagination {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 28px;

    display: flex;
    column-gap: 12px;

    cursor: pointer;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 11px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.32);

      &-active {
        background-color: map-get($colors, 'main');
      }
    }
  }
}

// @media (min-width: 576px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 540px) / -2 - 20px);
//       margin-right: calc((100vw - 540px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 768px) {
  .screen {
    padding-top: 110px;

    &__slide {
      height: 640px;
    }

    &__block {
      top: 110px;

      height: calc(100% - 110px);
    }

    &__content {
      padding-top: 129px;
      padding-bottom: 177px;
    }

    &__stars {
      margin-bottom: 27px;
    }

    &__title {
      max-width: 622px;
      margin-bottom: 32px;
    }

    &__text {
      width: 305px;

      font-size: 15px;
    }

    &__pagination {
      bottom: 56px;
    }
  }
}

// @media (min-width: 992px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 960px) / -2 - 20px);
//     }

//     &__image {
//       left: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .screen {
    &__title {
      max-width: 752px;
      margin-bottom: 18px;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(200px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
