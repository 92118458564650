.module {
  display: flex;
  align-items: flex-start;

  &.inverted {
    .checkbox {
      border: 1px solid #fff;
    }

    .text {
      color: rgba(255, 255, 255, 0.4);
    }

    .button {
      color: #fff;
    }
  }
}

.checkbox {
  flex-shrink: 0;
  margin-right: 10px;
}

.block {
  font-size: 14px;
  line-height: 1.35;
}

.text {
  color: #143437;
}

.button {
  text-decoration: underline;

  color: #bf9570;
  cursor: pointer;
}
