.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  margin-bottom: 15px;

  background-color: rgba(255, 255, 255, 0.57);
}

.phone {
  margin-bottom: 8px;

  background-color: rgba(255, 255, 255, 0.57);
}
