.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 570px;
  max-width: 100%;
  padding-top: 105px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.icon {
  position: relative;

  width: 54px;
  aspect-ratio: 1;
  margin-bottom: 18px;

  border-radius: 50%;
  background-color: #1191a5;

  &::before {
    position: absolute;
    top: 18px;
    left: 16px;

    width: 21px;
    height: 11px;

    border-left: 1px solid rgba(255, 255, 255, 0.63);
    border-bottom: 1px solid rgba(255, 255, 255, 0.63);
    transform: rotate(-45deg);

    content: '';
  }
}

.title {
  margin-bottom: 13px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;
  text-align: center;
}

.subtitle {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.42;
  text-align: center;

  color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 1200px) {
}
