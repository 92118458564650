.gallery {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 28px;
  }

  &__first {
    display: flex;
    margin-bottom: 23px;
  }

  &__one {
    display: flex;
    aspect-ratio: 1 / 1.16;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__second {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
  }

  &__two {
    display: flex;
    width: 100%;
    aspect-ratio: 1 / 0.63;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__three {
    display: flex;
    width: calc(50% - 15px / 2);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__four {
    display: flex;
    width: calc(50% - 15px / 2);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px) {
  .gallery {
    &__content {
      display: flex;
      padding-top: 54px;
      padding-bottom: 33px;
    }

    &__first {
      width: calc(50% - 10px);
      margin-right: 10px;
      margin-bottom: 0;
    }

    &__second {
      row-gap: 20px;
      column-gap: 20px;
      width: calc(50% - 10px);
      margin-left: 10px;
    }

    &__three {
      width: calc(50% - 10px);
    }

    &__four {
      width: calc(50% - 10px);
    }
  }
}

@media (min-width: 1200px) {
  .gallery {
    &__content {
      padding-top: 61px;
      padding-bottom: 108px;
    }

    &__first {
      width: calc(50% - 15px);
      margin-right: 15px;
    }

    &__second {
      row-gap: 30px;
      column-gap: 30px;
      width: calc(50% - 15px);
      margin-left: 15px;
    }

    &__three {
      width: calc(50% - 15px);
    }

    &__four {
      width: calc(50% - 15px);
    }
  }
}
