.header {
  $p: &;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;

  background-color: #000;

  // &_sticked {
  //   background-color: map-get($colors, 'main');

  //   transition: 0.2s;
  // }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;

    background-color: #000;

    img {
      width: 46px;
    }
  }

  &__block {
    display: none;
  }

  &__phone {
    transition: color 0.2s;

    &:hover {
      color: map-get($colors, 'second');
    }
  }

  &__link {
    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second');
    }
  }

  &__callback {
    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second');
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 42px;
    height: 42px;

    &::before {
      width: 25px;
      height: 1px;
      margin-bottom: 7px;

      background-color: #fff;

      transition: background 0.2s;

      content: '';
    }

    &::after {
      width: 25px;
      height: 1px;

      transition: background 0.2s;

      background-color: #fff;

      content: '';
    }

    span {
      width: 25px;
      height: 1px;
      margin-bottom: 7px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      background-color: #fff;

      transition: background 0.2s;
    }

    // &:hover {
    //   span {
    //     background-color: map-get($colors, 'second');
    //   }

    //   &::before {
    //     background-color: map-get($colors, 'second');
    //   }

    //   &::after {
    //     background-color: map-get($colors, 'second');
    //   }
    // }
  }

  &_opened {
    #{$p}__block {
      position: fixed;
      top: 68px;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 39px;

      background-color: #1c1c1d;
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 22px;
      margin-bottom: 53px;

      a {
        display: flex;

        font-size: 12px;
        line-height: 1.16;
        text-transform: uppercase;

        color: #fff;

        transition: color 0.2s;

        &:hover {
          color: map-get($colors, 'second');
        }
      }
    }

    #{$p}__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #{$p}__address {
      display: none;
    }

    #{$p}__phone {
      margin-bottom: 13px;

      img {
        display: none;
      }

      span {
        font-size: 18px;
        line-height: 1.16;

        color: #fff;
      }
    }

    #{$p}__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 43px;

      font-size: 12px;
      text-transform: uppercase;

      background-color: map-get($colors, 'main');
      color: #fff;
    }

    #{$p}__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 43px;

      font-size: 12px;
      text-transform: uppercase;

      background-color: map-get($colors, 'main');
      color: #fff;
    }

    #{$p}__button {
      align-items: center;

      span {
        display: none;
      }

      &::before {
        width: 32px;
        margin-bottom: 0;

        background-color: #fff;
        transform: rotate(45deg);
      }

      &::after {
        width: 32px;
        margin-top: -1px;

        background-color: #fff;
        transform: rotate(-45deg);
      }
    }
  }
}

// @media (min-width: 576px) {
//   .contacts {
//     &__map {
//       margin-left: calc((100vw - 540px) / -2 - 15px);
//       margin-right: calc((100vw - 540px) / -2 - 15px);
//     }
//   }
// }

@media (min-width: 768px) {
  .header {
    &__content {
      justify-content: flex-start;
      align-items: flex-start;
      height: 110px;
    }

    &__logo {
      width: 114px;
      height: 110px;

      img {
        width: 75px;
      }
    }

    &__block {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__nav {
      order: 1;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 35px;

      a {
        font-size: 12px;
        line-height: 1.17;
        text-transform: uppercase;

        color: #fff;

        transition: color 0.2s;

        &:hover {
          color: map-get($colors, 'main');
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
      margin-right: calc((100vw - 720px) / -2);
      padding-left: 18px;
      padding-right: calc((100vw - 720px) / 2);

      border-bottom: 1px solid #c4c4c4;
    }

    &__address {
      display: flex;
      align-items: flex-start;
      max-width: 200px;
      margin-right: auto;

      img {
        margin-right: 12px;
      }

      span {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;

        color: #fff;
      }
    }

    &__phone {
      display: flex;
      align-items: center;
      margin-right: 22px;

      img {
        margin-right: 12px;
      }

      span {
        font-size: 15px;
        line-height: 1.2;

        color: #fff;

        transition: color 0.2s;
      }

      &:hover {
        span {
          color: map-get($colors, 'main');
        }
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 194px;
      height: 53px;

      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;

      color: #fff;
      background-color: map-get($colors, 'main');

      transition: background 0.2s;

      &:hover {
        background-color: map-get($colors, 'second');
      }
    }

    &__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 194px;
      height: 53px;

      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;

      color: #fff;
      background-color: map-get($colors, 'main');

      transition: background 0.2s;

      &:hover {
        background-color: map-get($colors, 'second');
      }
    }

    &__button {
      display: none;
    }
  }
}

// @media (min-width: 992px) {
//   .header {
//     &__nav {
//       left: calc((100vw - 960px) / -2 - 20px);
//       right: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .header {
    &__nav {
      justify-content: flex-end;
      column-gap: 42px;
      padding-left: 0;
    }

    &__info {
      margin-right: calc((100vw - 1140px) / -2);
      padding-right: calc((100vw - 1140px) / 2);
    }

    &__address {
      align-items: center;
      max-width: none;

      span {
        font-size: 14px;
      }
    }

    &__phone {
      margin-right: 28px;

      img {
        margin-right: 18px;
      }

      span {
        font-size: 18px;
      }
    }
  }
}

// @media (hover: hover) {
//   .header {
//     &__nav {
//       a {
//         &:hover {
//           color: map-get($colors, 'main-light');
//         }
//       }
//     }

//     &__phone {
//       &:hover {
//         color: map-get($colors, 'main');
//       }
//     }

//     &__callback {
//       &:hover {
//         color: #fff;
//         background-color: map-get($colors, 'main');
//       }
//     }
//   }
// }
