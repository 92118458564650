.footer {
  &__top {
    background-color: #000;
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 34px;
  }

  &__logo {
    width: 75px;
    margin-bottom: 40px;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
    margin-bottom: 46px;

    a {
      font-size: 12px;
      text-transform: uppercase;

      color: #fff;

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'second');
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__phone {
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    img {
      margin-right: 12px;
    }

    span {
      font-size: 18px;
      line-height: 1.17;

      color: #fff;

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'main');
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 43px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second');
    }
  }

  &__callback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 43px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'second');
    }
  }

  &__bottom {
    background-color: map-get($colors, 'bg');
  }

  &__bottom-content {
    padding-top: 29px;
    padding-bottom: 38px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 19px;
    margin-bottom: 19px;

    button,
    a {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.42;

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'second');
      }
    }
  }

  &__copyright {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.42;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .footer {
    &__top {
      position: relative;
    }

    &__top-content {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: 62px;
    }

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 114px;
      height: 110px;
      margin-bottom: 0;

      background-color: #000;

      img {
        width: 75px;
      }
    }

    &__nav {
      position: absolute;
      left: 0;
      bottom: 0;

      flex-direction: row;
      justify-content: center;
      column-gap: 23px;
      width: 100%;
      height: 62px;
      margin-bottom: 0;

      border-top: 1px solid #c4c4c4;
    }

    &__phone {
      margin-bottom: 12px;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 15px;
      }
    }

    &__link {
      width: 194px;
      height: 53px;
    }

    &__callback {
      width: 194px;
      height: 53px;
    }

    &__bottom-content {
      padding-top: 23px;
      padding-bottom: 16px;
    }

    &__buttons {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      padding-bottom: 21px;

      border-bottom: 1px solid #c4c4c4;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top-content {
      justify-content: flex-start;

      padding-top: 18px;
      padding-bottom: 28px;
    }

    &__logo {
      margin-right: auto;

      background-color: transparent;
    }

    &__nav {
      position: static;

      width: auto;
      height: auto;
      margin-right: 111px;

      border-top: none;
    }

    &__info {
      align-items: flex-end;
    }

    &__phone {
      span {
        font-size: 18px;
      }
    }

    &__link {
      width: 160px;
      height: 43px;
    }

    &__callback {
      width: 160px;
      height: 43px;
    }

    &__bottom-content {
      display: flex;

      padding-top: 18px;
      padding-bottom: 18px;
    }

    &__buttons {
      order: 1;
      flex-grow: 1;
      justify-content: space-between;

      padding-bottom: 0;
      margin-bottom: 0;

      border-bottom: none;
    }

    &__copyright {
      margin-right: 60px;
    }
  }
}
