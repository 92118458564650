.question {
  background-color: map-get($colors, 'main');

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    aspect-ratio: 1/ 0.68;
    margin-left: -15px;
    margin-right: -15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 46px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    color: #fff;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: rgba(255, 255, 255, 0.62);

      content: '';
    }
  }

  &__text {
    max-width: 220px;
    margin-bottom: 30px;

    font-size: 17px;
    line-height: 1.4;
    text-align: center;

    color: #fff;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 47px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    border: 1px solid #fff;
    color: #fff;

    transition: color 0.2s, background 0.2s;

    &:hover {
      color: map-get($colors, 'main');
      background-color: #fff;
    }
  }
}

@media (min-width: 576px) {
  .question {
    &__image {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .question {
    &__content {
      flex-direction: row;
    }

    &__image {
      width: calc((50% - 15px) + ((100vw - 720px) / 2));
      max-height: 404px;

      margin-left: calc((100vw - 720px) / -2);
      margin-right: 30px;
    }

    &__block {
      align-items: flex-start;
      padding-top: 80px;
      padding-bottom: 95px;
    }

    &__title {
      align-items: flex-start;
      margin-bottom: 52px;

      font-size: 35px;
    }

    &__text {
      margin-bottom: 42px;

      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    &__image {
      width: calc((58% - 15px) + ((100vw - 1140px) / 2));
      max-height: 462px;
      margin-right: 54px;
      margin-left: calc((100vw - 1140px) / -2);
    }

    &__block {
      padding-bottom: 107px;
    }

    &__title {
      margin-bottom: 77px;

      font-size: 45px;
    }

    &__text {
      margin-bottom: 51px;
    }
  }
}
