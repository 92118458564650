.module {
  position: relative;

  width: 743px;
  max-width: 100%;
  padding-top: 73px;
  // padding-left: 20px;
  // padding-right: 20px;
  padding-bottom: 60px;
  overflow: hidden;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.top {
  margin-bottom: 22px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 13px;

  border-bottom: 1px solid #c8c8c8;
}

.name {
  margin-bottom: 7px;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grade {
  font-size: 15px;
  line-height: 1.4;

  strong {
    font-weight: 700;

    color: #1191a5;
  }

  span {
    color: rgba(0, 0, 0, 0.3);
  }
}

.date {
  font-size: 14px;
  line-height: 1.14;

  color: #bebebe;
}

.bottom {
  padding-left: 20px;
  padding-right: 20px;
}

.text {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.42;

  color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 768px) {
  .top {
    padding-left: 53px;
    padding-right: 53px;
  }

  .bottom {
    padding-left: 53px;
    padding-right: 96px;
  }
}

@media (min-width: 1200px) {
}
