.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 186px;
}

.adults {
  margin-bottom: 15px;

  background-color: rgba(255, 255, 255, 0.57);
}

.babies {
  background-color: rgba(255, 255, 255, 0.57);
}

.babyList {
  margin-top: 15px;

  background-color: rgba(255, 255, 255, 0.57);
}
