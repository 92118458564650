.services {
  position: relative;

  background-color: map-get($colors, 'bg');

  #services {
    position: absolute;
    top: -68px;
  }

  &__content {
    padding-top: 55px;
    padding-bottom: 56px;
  }

  &__title {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 47px;

    font-size: 30px;
    font-weight: 300;
    line-height: 1.16;
    text-align: center;

    &::after {
      position: absolute;
      top: calc(100% + 10px);

      width: 50px;
      height: 3px;

      background-color: #c8c8c8;

      content: '';
    }
  }

  &__slider {
    position: relative;
  }

  &__slide {
    display: flex;
    flex-direction: column;
  }

  &__image {
    position: relative;

    display: flex;
    aspect-ratio: 1 / 0.68;
    margin-bottom: 5px;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin-bottom: 20px;
    padding-bottom: 5px;

    font-size: 18px;
    line-height: 1.42;

    border-bottom: 1px solid #c8c8c8;
  }

  &__bottom {
    display: flex;
  }

  &__text {
    max-width: 90%;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.42;

    color: rgba(0, 0, 0, 0.71);
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 39px;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    border: 1px solid map-get($colors, 'second');
    color: map-get($colors, 'second');

    transition: color 0.2s, background 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, 'second');
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;
    margin-top: calc(100% * 0.68 / 2);

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;
    margin-top: calc(100% * 0.68 / 2);

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(5, 119, 168, 0.51);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .services {
    overflow: hidden;

    #services {
      top: -110px;
    }

    &__content {
      padding-top: 68px;
    }

    &__title {
      margin-bottom: 51px;

      font-size: 45px;
    }

    &__slider {
      .swiper {
        overflow: visible;
      }

      .swiper-slide {
        &:not(.swiper-slide-active):not(.swiper-slide-active + .swiper-slide) {
          opacity: 0.3;

          pointer-events: none;
        }
      }
    }

    &__image {
      margin-bottom: 17px;
    }

    &__name {
      margin-bottom: 20px;
      padding-bottom: 10px;

      font-size: 20px;
    }

    &__text {
      font-size: 15px;
    }

    &__button {
      width: 143px;
      height: 47px;
    }

    &__prev {
      margin-top: calc(((100% - 10px) / 2) * 0.68 / 2);

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__next {
      margin-top: calc(((100% - 10px) / 2) * 0.68 / 2);

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .services {
    &__content {
      padding-top: 109px;
      padding-bottom: 82px;
    }

    &__title {
      margin-bottom: 83px;
    }

    &__slider {
      .swiper-slide-active + .swiper-slide + .swiper-slide {
        opacity: 1 !important;

        pointer-events: initial !important;
      }
    }

    &__image {
      margin-bottom: 10px;
    }

    &__name {
      margin-bottom: 20px;
    }

    &__prev {
      left: -29px;

      width: 58px;
      margin-top: calc(((100% - 20px) / 3) * 0.68 / 2);

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        left: 26px;

        width: 12px;
      }
    }

    &__next {
      right: -29px;

      width: 58px;
      margin-top: calc(((100% - 20px) / 3) * 0.68 / 2);

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        right: 26px;

        width: 12px;
      }
    }
  }
}
