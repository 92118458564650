.module {
  min-height: 155px;
  padding: 18px 22px;

  border: 1px solid #c6c6c6;

  &::placeholder {
    color: rgba(0, 0, 0, 0.41);
  }
}
